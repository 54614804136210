<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Что нужно сделать до старта:</p>
            <p>1.<b> Подготовить <a target='_blank' href="/files/Materials_begginnersV2.pdf">необходимые материалы.</a></b></p>
            <p>2. <b>Вступить в <a target='_blank' href="https://vk.com/invite/HSdVXl8">закрытую группу Вконтакте</a></b> 
            (там вы сможете общаться с кураторами, в чате и отслеживать появление новых уроков). </p>
            <p>3. <b>Обязательно <a target='_blank' href="https://vk.com/app5898182_-202330802#s=1330630">подписаться на получение уроков</a></b></p>
            
            <div class="small">
                <p>Если у вас проблемы с Вконтакте, вы не можете зайти в него (Украина), то попробуйте <a target='_blank' href='https://www.torproject.org/ru/'>Tor браузер </a>(он со встроенным VPN), просто установите его и авторизуйтесь в Вк</p>
                <p class='nomargin'>Если вы даже не хотите связываться с Вконтакте, то учитывайте, что вы отказываетесь:</p>
                <ul>
                    <li>От получения сообщений о выходе новых уроков (если что все сообщения будут дублироваться на почту)</li>
                    <li>От закрытого чата (если он предусмотрен у вас на тарифе)</li>
                    <li>От общения с куратором (если он предусмотрен у вас на тарифе)</li>
                </ul>
            </div>
           
        </div>
    </KCourse>
</template>

<script>
    // import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js'
    export default {
        name: "MBCourseHead",
        components: {
            // VideoView,
            KCourse,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>